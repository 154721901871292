<!--
 * @Description:考核指标
 * @Author: ChenXueLin
 * @Date: 2022-03-04 09:28:40
 * @LastEditTime: 2022-07-15 16:02:31
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item prop="indicatorType">
              <e6-vr-select
                v-model="searchForm.indicatorType"
                :data="checkIndicatorTypeList"
                placeholder="考核指标"
                title="考核指标"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.row.sortIndex }}
              </span>
            </template>
          </el-table-column>

          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="100"
          >
            <template slot-scope="scope">
              <e6-td-operate
                v-if="scope.row.sortIndex"
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
    </div>
    <el-dialog
      v-dialogDrag
      title="编辑"
      :visible="editDialog"
      width="800px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      v-loading="loading"
      custom-class="edit-dialog"
    >
      <el-form label-width="90px" :inline="true">
        <el-form-item label="考核指标:">
          {{ clickRow.indicatorTypeName }}
        </el-form-item>
        <el-form-item label="考核范围:">
          {{ clickRow.checkRange }}
        </el-form-item>
      </el-form>
      <el-form label-width="90px" ref="editForm" :model="editForm">
        <el-form-item label="指标含义:" prop="indicatorDesc">
          <el-input
            placeholder="请输入"
            v-model="editForm.indicatorDesc"
            style="width:350px"
            :disabled="editType == 'detail' ? true : false"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-form label-width="90px" :inline="true">
        <el-row>
          <el-col :span="8">
            <el-form-item label="类型:" prop="changeDate">
              {{ clickRow.checkType }}
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="时间范围:" prop="time">
              <e6-time-picker
                is-range
                v-model="editForm.time"
                value-format="HH:mm"
                @change="
                  (val, node) => {
                    changeTime(val, node, 'time');
                  }
                "
                :disabled="editType == 'detail' ? true : false"
              >
              </e6-time-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="考核节点:" prop="changeDate">
              {{ clickRow.timeOffset }}({{ clickRow.unit }})
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="扣款金额:" prop="deductMoney">
              <el-input
                v-model="editForm.deductMoney"
                style="width:80px;"
                :disabled="editType == 'detail' ? true : false"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="类型:" prop="changeDate">
              {{ clickRow.outerCheckType }}
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="时间范围:" prop="time">
              <e6-time-picker
                is-range
                v-model="outTime"
                value-format="HH:mm"
                @change="
                  (val, node) => {
                    changeTime(val, node, 'outTime');
                  }
                "
                :disabled="editType == 'detail' ? true : false"
              >
              </e6-time-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="考核节点:" prop="changeDate">
              {{ clickRow.outerTimeOffset }}({{ clickRow.outerUnit }})
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="扣款金额:" prop="outerDeductMoney">
              <el-input
                v-model="editForm.outerDeductMoney"
                style="width:80px;"
                :disabled="editType == 'detail' ? true : false"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer" slot="footer" v-if="editType == 'edit'">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import {
  getCheckIndicatorList,
  updateCheckIndicatorList,
  findDownList
} from "@/api";
export default {
  name: "assessmentList",
  data() {
    return {
      searchForm: {
        indicatorType: "",
        pageIndex: 1,
        pageSize: 20
      },
      queryListAPI: getCheckIndicatorList,
      total: 0,
      loading: false,
      columnData: [
        {
          fieldName: "indicatorTypeName",
          display: true,
          fieldLabel: "考核指标",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "indicatorDesc",
          display: true,
          fieldLabel: "指标含义",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "checkRange",
          display: true,
          fieldLabel: "考核范围",
          width: 110,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "checkType",
          display: true,
          fieldLabel: "类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "timeStr",
          display: true,
          fieldLabel: "时间范围",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "timeOffsetStr",
          display: true,
          fieldLabel: "考核节点",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "deductMoney",
          display: true,
          fieldLabel: "扣款金额（元）",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskStatusName",
          display: true,
          fieldLabel: "操作员",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskStatusName",
          display: true,
          fieldLabel: "最后修改时间",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      checkIndicatorTypeList: [],
      editType: "edit",
      tableData: [], // 表格数据
      editDialog: false,
      clickRow: {},
      outTime: [],
      editForm: {
        indicatorDesc: "",
        deductMoney: "",
        outerDeductMoney: "",
        startTime: "",
        endTime: "",
        time: []
      }
    };
  },
  mixins: [listPage, base, listPageReszie],
  components: {},
  computed: {},
  watch: {},
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [findDownList(["checkIndicatorType"])];
        let [Res] = await Promise.all(promiseList);
        this.checkIndicatorTypeList = this.getFreezeResponse(Res, {
          path: "data.checkIndicatorType"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    // 操作--更多
    handleOperate(val, row = {}) {
      this.editDialog = true;
      this.clickRow = row;
      this.editForm.indicatorDesc = row.indicatorDesc;
      this.editForm.deductMoney = row.deductMoney;
      this.editForm.outerDeductMoney = row.outerDeductMoney;
      this.editForm.time = [row.startTime, row.endTime];
      this.editForm.startTime = row.startTime;
      this.editForm.endTime = row.endTime;
      this.outTime = [row.endTime, row.startTime];
      this.editType = val.id == 1 ? "edit" : "detail";
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        },
        {
          label: "详情",
          id: 2
        }
      ];
      return operates;
    },
    // 格式化数据
    formatList(data) {
      let newData = [];
      let pageIndex = this.searchForm.pageIndex;
      let pageSize = this.searchForm.pageSize;
      data.map((item, index) => {
        item.timeStr = item.include
          ? item.startTime + "~" + item.endTime + "(" + item.include + ")"
          : item.startTime + "~" + item.endTime;
        item.sortIndex = index + 1 + (pageIndex - 1) * pageSize;
        item.timeOffsetStr = item.timeOffset + item.unit;
        newData.push(item);
        let obj = {
          checkRange: item.outerCheckRange,
          checkType: item.outerCheckType,
          deductMoney: item.outerDeductMoney,
          timeStr: item.outerInclude
            ? item.outerStartTime +
              "~" +
              item.outerEndTime +
              "(" +
              item.outerInclude +
              ")"
            : item.outerStartTime + "~" + item.outerEndTime,
          timeOffsetStr: item.outerTimeOffset + item.outerUnit
        };
        newData.push(obj);
      });
      return newData;
    },
    changeTime(val, b, timeType) {
      if (timeType == "time") {
        this.outTime = _.cloneDeep(this.editForm.time).reverse();
      } else {
        this.editForm.time = _.cloneDeep(this.outTime).reverse();
      }
    },
    handleSubmit() {
      this.updateCheckIndicatorListReq();
    },
    async updateCheckIndicatorListReq() {
      try {
        this.loading = true;
        let res = await updateCheckIndicatorList({
          ...this.editForm,
          indicatorId: this.clickRow.indicatorId,
          startTime: this.editForm.time[0],
          endTime: this.editForm.time[1]
        });
        if (res.code == "OK") {
          this.$message.success("更新成功");
          this.handleSearch();
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.editDialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.e6-time-picker {
  .is-range {
    width: 230px;
  }
}
</style>
